<template>
    <div class="app-container">
        <!--角色数据-->
        <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="90px">
            <el-form-item label="角色名称：" prop="roleName">
                <el-input v-model="queryParams.roleName" placeholder="请输入角色名称" clearable class="width_170" @keyup.enter.native="handleQuery" />
            </el-form-item>
            <el-form-item label="权限字符：" prop="roleKey">
                <el-input v-model="queryParams.roleKey" placeholder="请输入权限字符" clearable class="width_170" @keyup.enter.native="handleQuery" />
            </el-form-item>
            <el-form-item label="状态：" prop="status">
                <el-select v-model="queryParams.status" placeholder="角色状态" clearable class="width_170">
                    <el-option label="正常" value="0" />
                    <el-option label="停用" value="1" />
                </el-select>
            </el-form-item>
            <el-form-item label="创建时间：" class="dateBox" prop="startTime">
                <el-row style="width: 320px">
                    <el-col :span="11">
                        <el-date-picker placeholder="开始时间" v-model="queryParams.startCreateTime" type="date" value-format="yyyy-MM-dd HH:mm:ss" style="width: 100%"> </el-date-picker>
                    </el-col>
                    <el-col class="line" style="text-align: center" :span="2">至</el-col>
                    <el-col :span="11">
                        <el-date-picker placeholder="结束时间" v-model="queryParams.endCreateTime" type="date" value-format="yyyy-MM-dd HH:mm:ss" style="width: 100%"> </el-date-picker>
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
            </el-form-item>
        </el-form>
        <div class="mb8">
            <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
            <!-- <el-button type="danger" plain icon="el-icon-delete" size="mini" :disabled="multiple" @click="handleDelete">删除</el-button> -->
        </div>
        <el-table v-loading="loading" :data="userList" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="50" align="center" />
            <el-table-column label="角色编号" prop="roleId" />
            <el-table-column label="角色名称" prop="roleName" :show-overflow-tooltip="true" />
            <el-table-column label="权限字符" prop="roleKey" :show-overflow-tooltip="true" />
            <el-table-column label="显示顺序" prop="roleSort" />
            <el-table-column label="状态" align="center" key="status">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.status" active-value="0" inactive-value="1" @change="handleStatusChange(scope.row)"></el-switch>
                </template>
            </el-table-column>
            <el-table-column label="创建时间" align="center" prop="createTime" width="150">
                <template slot-scope="scope">
                    <span>{{ parseTime(scope.row.createTime) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
                <template slot-scope="scope" v-if="scope.row.userId !== -1">
                    <el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(scope.row)">修改</el-button>
                    <!-- <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="handleDelete(scope.row)"
            >删除</el-button> -->
                    <!-- <el-dropdown size="mini" @command="(command) => handleCommand(command, scope.row)">
              <el-button size="mini" type="text" icon="el-icon-d-arrow-right">更多</el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="handleResetPwd" icon="el-icon-key"
                  >重置密码</el-dropdown-item>
                <el-dropdown-item command="handleAuthRole" icon="el-icon-circle-check"
                  >分配角色</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown> -->
                </template>
            </el-table-column>
        </el-table>

        <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNo" :limit.sync="queryParams.pageSize" @pagination="getList" />

        <!-- 添加或修改角色配置对话框 -->
        <el-dialog :title="title" :visible.sync="open" width="600px" append-to-body>
            <el-form ref="form" :model="form" :rules="rules" label-width="100px">
                <el-form-item label="角色名称" prop="roleName">
                    <el-input v-model="form.roleName" placeholder="请输入角色名称" />
                </el-form-item>
                <el-form-item prop="roleKey">
                    <span slot="label">
                        <el-tooltip content="控制器中定义的权限字符，如：@PreAuthorize(`@ss.hasRole('admin')`)" placement="top">
                            <i class="el-icon-question"></i>
                        </el-tooltip>
                        权限字符
                    </span>
                    <el-input v-model="form.roleKey" placeholder="请输入权限字符" />
                </el-form-item>
                <el-form-item label="角色顺序" prop="roleSort">
                    <el-input-number v-model="form.roleSort" controls-position="right" :min="0" />
                </el-form-item>
                <el-form-item label="状态">
                    <el-select v-model="form.status" placeholder="请选择状态">
                        <el-option label="正常" value="0" />
                        <el-option label="停用" value="1" />
                    </el-select>
                </el-form-item>
                <el-form-item label="菜单权限">
                    <el-checkbox v-model="menuExpand" @change="handleCheckedTreeExpand($event, 'menu')">展开/折叠</el-checkbox>
                    <el-checkbox v-model="menuNodeAll" @change="handleCheckedTreeNodeAll($event, 'menu')">全选/全不选</el-checkbox>
                    <!-- <el-checkbox v-model="form.menuCheckStrictly" @change="handleCheckedTreeConnect($event, 'menu')">父子联动</el-checkbox> -->
                    <el-tree class="tree-border" :data="menuOptions" show-checkbox ref="menu" node-key="menuId" :check-strictly="!form.menuCheckStrictly" empty-text="加载中，请稍候" :props="defaultProps"></el-tree>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="form.remark" type="textarea" placeholder="请输入内容"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>

        <!-- 角色导入对话框 -->
        <el-dialog :title="title" :visible.sync="openDataScope" width="500px" append-to-body>
            <el-form :model="form" label-width="80px">
                <el-form-item label="角色名称">
                    <el-input v-model="form.roleName" :disabled="true" />
                </el-form-item>
                <el-form-item label="权限字符">
                    <el-input v-model="form.roleKey" :disabled="true" />
                </el-form-item>
                <el-form-item label="权限范围">
                    <el-select v-model="form.dataScope" @change="dataScopeSelectChange">
                        <el-option v-for="item in dataScopeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="数据权限" v-show="form.dataScope == 2">
            <el-checkbox v-model="deptExpand" @change="handleCheckedTreeExpand($event, 'dept')">展开/折叠</el-checkbox>
            <el-checkbox v-model="deptNodeAll" @change="handleCheckedTreeNodeAll($event, 'dept')">全选/全不选</el-checkbox>
            <el-checkbox v-model="form.deptCheckStrictly" @change="handleCheckedTreeConnect($event, 'dept')">父子联动</el-checkbox>
            <el-tree
              class="tree-border"
              :data="deptOptions"
              show-checkbox
              default-expand-all
              ref="dept"
              node-key="id"
              :check-strictly="!form.deptCheckStrictly"
              empty-text="加载中，请稍候"
              :props="defaultProps"
            ></el-tree>
          </el-form-item> -->
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitDataScope">确 定</el-button>
                <el-button @click="cancelDataScope">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
  
  <script>
//   import { listUser, getUser, delUser, addUser, updateUser, resetUserPwd, changeUserStatus, deptTreeSelect } from "@/api/system/user";
import { getToken } from '@/utils/auth';

export default {
    name: 'roleAdminHl',
    dicts: ['sys_normal_disable', 'sys_user_sex'],
    components: {},
    data() {
        return {
            // 遮罩层
            loading: false,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            // 角色表格数据
            userList: null,
            // 弹出层标题
            title: '',
            // 部门树选项
            deptOptions: undefined,
            // 是否显示弹出层
            open: false,
            // 部门名称
            deptName: undefined,
            // 默认密码
            initPassword: undefined,
            // 日期范围
            dateRange: [],
            // 岗位选项
            postOptions: [],
            // 角色选项
            roleOptions: [],
            dictOption: [],
            // 表单参数
            form: {},
            defaultProps: {
                children: 'children',
                label: 'menuName'
            },
            // 菜单列表
            menuOptions: [],
            // 角色导入参数
            upload: {
                // 是否显示弹出层（角色导入）
                open: false,
                // 弹出层标题（角色导入）
                title: '',
                // 是否禁用上传
                isUploading: false,
                // 是否更新已经存在的角色数据
                updateSupport: 0,
                // 设置上传的请求头部
                headers: { Authorization: 'Bearer ' + getToken() },
                // 上传的地址
                url: process.env.VUE_APP_BASE_API + '/system/user/importData'
            },
            dataScopeOptions: [
                {
                    value: '1',
                    label: '全部数据权限'
                },
                {
                    value: '2',
                    label: '自定数据权限'
                },
                {
                    value: '3',
                    label: '本部门数据权限'
                },
                {
                    value: '4',
                    label: '本部门及以下数据权限'
                },
                {
                    value: '5',
                    label: '仅本人数据权限'
                }
            ],
            menuExpand: false,
            menuNodeAll: false,
            // 是否显示弹出层（数据权限）
            openDataScope: false,
            // 查询参数
            queryParams: {
                pageNo: 1,
                pageSize: 10,
                startCreateTime: '',
                endCreateTime: '',
                roleName: undefined,
                roleKey: undefined,
                status: undefined
            },
            // 表单校验
            rules: {
                userName: [
                    { required: true, message: '角色名称不能为空', trigger: 'blur' },
                    { min: 2, max: 20, message: '角色名称长度必须介于 2 和 20 之间', trigger: 'blur' }
                ],
                nickName: [{ required: true, message: '角色昵称不能为空', trigger: 'blur' }],
                password: [
                    { required: true, message: '角色密码不能为空', trigger: 'blur' },
                    { min: 5, max: 20, message: '角色密码长度必须介于 5 和 20 之间', trigger: 'blur' }
                ],
                email: [
                    {
                        type: 'email',
                        message: '请输入正确的邮箱地址',
                        trigger: ['blur', 'change']
                    }
                ],
                phoneNumber: [
                    {
                        pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
                        message: '请输入正确的手机号码',
                        trigger: 'blur'
                    }
                ]
            },
            showMenuArr: []
        };
    },
    watch: {},
    created() {
        this.getList();
        this.getMenuTreeselect();
        //   this.getConfigKey("sys.user.initPassword").then(response => {
        //     this.initPassword = response.msg;
        //   });
    },
    methods: {
        handleCheckedTreeNodeAll() {},
        handleCheckedTreeExpand() {
            if (this.menuExpand) {
                for (var i in this.$refs.menu.store.nodesMap) {
                    this.$refs.menu.store.nodesMap[i].expanded = true;
                }
            } else {
                for (var j in this.$refs.menu.store.nodesMap) {
                    this.$refs.menu.store.nodesMap[j].expanded = false;
                }
            }
        },
        /** 查询角色列表 */
        getList() {
            this.loading = true;
            this.tableData = []; //  清空table的值
            var queryParams = JSON.parse(JSON.stringify(this.queryParams));
            let json = {
                condition: queryParams,
                pageNo: queryParams.pageNo,
                pageSize: queryParams.pageSize
            };
            this.instance.post('/upms/api/role/paging', json).then((res) => {
                // console.log(res);
                this.loading = false;
                this.userList = res.data.data.list;
                this.total = parseInt(res.data.data.total);
            });
        },
        // 角色状态修改
        handleStatusChange(row) {
            let text = row.status === '0' ? '启用' : '停用';
            this.$modal
                .confirm('确认要"' + text + '""' + row.roleName + '"角色吗？')
                .then(() => {
                    let json = {
                        userId: row.userId,
                        status: row.status
                    };
                    this.instance.post('/upms/api/user/edit', json).then((res) => {});
                    this.$modal.msgSuccess(text + '成功');
                })
                .catch(function () {
                    row.status = row.status === '0' ? '1' : '0';
                });
        },
        /** 查询菜单树结构 */
        getMenuTreeselect() {
            this.instance.get('/upms/api/menu/getMenuTreeByTenantId', {}).then((res) => {
                if (res.data.code == 200) {
                    this.menuOptions = res.data.data;
                }
            });
        },
        // 取消按钮
        cancel() {
            this.open = false;
            this.reset();
        },
        // 取消按钮（数据权限）
        cancelDataScope() {
            this.openDataScope = false;
            this.reset();
        },
        /** 选择角色权限范围触发 */
        dataScopeSelectChange(value) {
            if (value !== '2') {
                this.$refs.dept.setCheckedKeys([]);
            }
        },
        /** 提交按钮（数据权限） */
        submitDataScope() {
            if (this.form.roleId != undefined) {
                this.form.deptIds = this.getDeptAllCheckedKeys();
                dataScope(this.form).then((response) => {
                    this.$modal.msgSuccess('修改成功');
                    this.openDataScope = false;
                    this.getList();
                });
            }
        },
        // 表单重置
        reset() {
            (this.menuExpand = false),
                (this.menuNodeAll = false),
                (this.form = {
                    roleId: undefined,
                    roleName: undefined,
                    roleKey: undefined,
                    roleSort: 0,
                    status: '0',
                    menuIdList: [],
                    menuCheckStrictly: true,
                    deptCheckStrictly: true,
                    remark: undefined
                });
            this.resetForm('form');
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNo = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.dateRange = [];
            this.resetForm('queryForm');
            this.queryParams.deptId = undefined;
            this.$refs.tree.setCurrentKey(null);
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map((item) => item.userId);
            this.single = selection.length != 1;
            this.multiple = !selection.length;
        },
        // 更多操作触发
        handleCommand(command, row) {
            switch (command) {
                case 'handleResetPwd':
                    this.handleResetPwd(row);
                    break;
                case 'handleAuthRole':
                    this.handleAuthRole(row);
                    break;
                default:
                    break;
            }
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.reset();
            this.open = true;
            this.title = '添加角色';
            // getUser().then(response => {
            //   this.postOptions = response.posts;
            //   this.roleOptions = response.roles;
            //   this.open = true;
            //   this.title = "添加角色";
            //   this.form.password = this.initPassword;
            // });
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            this.instance.get('/upms/api/role/getRoleDetail', { params: { roleId: row.roleId } }).then((res) => {
                if (res.data.code == 200) {
                    let response = res.data.data;
                    this.form = response;
                    this.open = true;
                    this.title = '修改角色';
                    if (response.menuIdList.length > 0) {
                        setTimeout(() => {
                            response.menuIdList.forEach((val) => {
                                this.$refs.menu.setChecked(val, true, false);
                            });
                        }, 200);
                    }
                } else {
                    this.$modal.msgError(res.data.message);
                }
            });
        },
        /** 重置密码按钮操作 */
        handleResetPwd(row) {
            this.$prompt('请输入"' + row.userName + '"的新密码', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                closeOnClickModal: false,
                inputPattern: /^.{5,20}$/,
                inputErrorMessage: '角色密码长度必须介于 5 和 20 之间'
            })
                .then(({ value }) => {
                    resetUserPwd(row.userId, value).then((response) => {
                        this.$modal.msgSuccess('修改成功，新密码是：' + value);
                    });
                })
                .catch(() => {});
        },
        /** 分配角色操作 */
        handleAuthRole: function (row) {
            const userId = row.userId;
            this.$router.push('/system/user-auth/role/' + userId);
        },
        /** 提交按钮 */
        submitForm() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.form.menuIdList = this.getMenuAllCheckedKeys();
                    if (this.form.roleId != undefined) {
                        this.instance.post('/upms/api/role/edit', this.form).then((res) => {
                            if (res.data.code == 200) {
                                this.$modal.msgSuccess('修改成功');
                                this.open = false;
                                this.getList();
                            } else {
                                this.$modal.msgError(res.data.message);
                            }
                        });
                    } else {
                        this.instance.post('/upms/api/role/add', this.form).then((res) => {
                            if (res.data.code == 200) {
                                this.$modal.msgSuccess('新增成功');
                                this.open = false;
                                this.getList();
                            } else {
                                this.$modal.msgError(res.data.message);
                            }
                        });
                    }
                }
            });
        },
        // 所有菜单节点数据
        getMenuAllCheckedKeys() {
            // 目前被选中的菜单节点
            let checkedKeys = this.$refs.menu.getCheckedKeys();
            // 半选中的菜单节点
            let halfCheckedKeys = this.$refs.menu.getHalfCheckedKeys();
            checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys);
            return checkedKeys;
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            const userIds = row.userId || this.ids;
            this.$modal
                .confirm('是否确认删除角色编号为"' + userIds + '"的数据项？')
                .then(function () {
                    return delUser(userIds);
                })
                .then(() => {
                    this.getList();
                    this.$modal.msgSuccess('删除成功');
                })
                .catch(() => {});
        },
        /** 导出按钮操作 */
        handleExport() {
            this.download(
                'system/user/export',
                {
                    ...this.queryParams
                },
                `user_${new Date().getTime()}.xlsx`
            );
        },
        /** 导入按钮操作 */
        handleImport() {
            this.upload.title = '角色导入';
            this.upload.open = true;
        },
        /** 下载模板操作 */
        importTemplate() {
            this.download('system/user/importTemplate', {}, `user_template_${new Date().getTime()}.xlsx`);
        },
        // 文件上传中处理
        handleFileUploadProgress(event, file, fileList) {
            this.upload.isUploading = true;
        },
        // 文件上传成功处理
        handleFileSuccess(response, file, fileList) {
            this.upload.open = false;
            this.upload.isUploading = false;
            this.$refs.upload.clearFiles();
            this.$alert("<div style='overflow: auto;overflow-x: hidden;max-height: 70vh;padding: 10px 20px 0;'>" + response.msg + '</div>', '导入结果', { dangerouslyUseHTMLString: true });
            this.getList();
        },
        // 提交上传文件
        submitFileForm() {
            this.$refs.upload.submit();
        }
    }
};
</script>