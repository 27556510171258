<template>
	<div class="wrap">
		<div class="page_title"><b>角色管理</b></div>
		<div class="main public_box">
			<role-admin-hl></role-admin-hl>
		</div>
	</div>
</template>

<script> 
// @ is an alias to /src   
import RoleAdminHl from '@/package/roleAdminHl/index.vue'
export default {
	name: 'roleAdmin',
	components: {
		RoleAdminHl
	},
	data(){
		return{
			loading:false,
			menuType:'searchReviewPage'
		}
	},
	methods:{
		
	},
	mounted() {  // 

	}
}
</script>
<style scoped>
	.main{
		margin-top: 0.2rem;
	}
</style>
